import AccountingInquiryState, {
  AccountingObject,
} from "@/types/state/accountingInquiry";
import { RootState } from "@/types/state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
// import { LiItem } from "@/types/accountsPayable";
import router from "@/router";
import GlChartService from "@/services/GlChartsService";
import FiscalYearService from "@/services/FiscalYearService";
import { GeneralLedgerEntry } from "@/types/state/generalLedger";

const glService = new GlChartService();
const fiscalYearService = new FiscalYearService();

const namespaced = true;

export const state: AccountingInquiryState = {
  AccountingInquiryMap: [],
  activeTab: 0,
  FiscalYears: [],
  Accounts: [],
};

export const getters: GetterTree<AccountingInquiryState, RootState> = {
  getAccountingInquiryMap(state) {
    return state.AccountingInquiryMap;
  },
  getActiveTab(state) {
    return state.activeTab;
  },
  getActiveOrderTab: (state) => (id: string) => {
    const item = state.AccountingInquiryMap.find(
      (item: any) => AccountingObject.hydrate(item)?.getRecordId() === id,
    );
    return AccountingObject.hydrate(item);
  },
  getNewPayableCount(state) {
    return state.AccountingInquiryMap.filter(
      (item: any) =>
        item.record.ap_id &&
        item.record.ap_id.startsWith("New") &&
        item.accountType === "accounts-payable",
    ).length;
  },
  getNewGLCount(state) {
    const newGL = state.AccountingInquiryMap.filter(
      (item: any) =>
        item.record.transaction_id &&
        item.record.transaction_id.startsWith("New") &&
        item.record instanceof GeneralLedgerEntry,
    );
    if (newGL.length === 0) return undefined;
    return parseInt(newGL[0].record.getId().split("-")[1]);
  },
  getLineItemsMap: (state) => (accountId: string) => {
    const index = state.AccountingInquiryMap.findIndex(
      (item: any) => item.record.ap_id === accountId,
    );
    if (index > -1) {
      if (state.AccountingInquiryMap[index].lineItemsMap) {
        return [...state.AccountingInquiryMap[index].lineItemsMap];
      }
    }
    return [];
  },
  getActiveLineItemsTab: (state) => (accountId: string) => {
    const index = state.AccountingInquiryMap.findIndex(
      (item: any) => item.record.ap_id === accountId,
    );
    if (index > -1) {
      if ((state.AccountingInquiryMap[index] as any).lineItemsActiveTab) {
        return (state.AccountingInquiryMap[index] as any).lineItemsActiveTab;
      }
    }
    return 0;
  },
  getActiveInnerTab: (state) => (accountId: string) => {
    const index = state.AccountingInquiryMap.findIndex(
      (account: any) =>
        account.record.ap_id === accountId ||
        account.record.transaction_id === accountId ||
        account.record.id === accountId,
    );
    if (index > -1) {
      return state.AccountingInquiryMap[index].activeInnerTab;
    }
    return 0;
  },
  getActiveInnerTabSuffix: (state, getters) => (accountId: string) => {
    const index = state.AccountingInquiryMap.findIndex(
      (account: any) =>
        account.record.ap_id === accountId ||
        account.record.transaction_id === accountId ||
        account.record.id === accountId,
    );
    if (index > -1) {
      const activeInnerTab =
        state.AccountingInquiryMap[index].activeInnerTab || 0;
      return getters.getInnerTabs(
        state.AccountingInquiryMap[index].accountType,
      )[activeInnerTab].suffix;
    }
    return "";
  },
  getInnerTabs: () => (accountType: string) => {
    if (accountType === "accounts-payable") {
      return [
        {
          label: "Header",
          prefix: "/accounting/accounts-payable",
          suffix: "",
        },
        {
          label: "Related AP Items",
          prefix: "/accounting/accounts-payable",
          suffix: "related-ap-items",
        },
        {
          label: "Register History",
          prefix: "/accounting/accounts-payable",
          suffix: "register-history",
        },
        {
          label: "Attachments",
          prefix: "/accounting/accounts-payable",
          suffix: "attachments",
        },
      ];
    }
    if (accountType === "gl") {
      return [
        {
          label: "GL Transaction",
          prefix: "/accounting/gl",
          suffix: "gl-transaction",
        },
        {
          label: "Attachments",
          prefix: "/accounting/gl",
          suffix: "attachments",
        },
      ];
    }
  },
  getLineItem: (state) => (accountId: string, lineItemId: string) => {
    const index = state.AccountingInquiryMap.findIndex(
      (item: any) => item.record.ap_id === accountId,
    );
    if (index > -1) {
      const lineItem = state.AccountingInquiryMap[index].lineItemsMap.find(
        (item: any) => item.li === lineItemId,
      );
      if (lineItem) {
        return lineItem;
      }
    }
    return null;
  },
  getFiscalYears(state) {
    return state.FiscalYears;
  },
  getGlAccounts(state) {
    return state.Accounts;
  },
};

export const mutations: MutationTree<AccountingInquiryState> = {
  PUSH_ACCOUNTING_INQUIRY_MAP(state, payload: any) {
    const data = { ...payload };
    state.AccountingInquiryMap = [data, ...state.AccountingInquiryMap];
  },
  PUSH_LINE_ITEM_MAP(state, payload: any) {
    const index = state.AccountingInquiryMap.findIndex(
      (item: any) => item.record.ap_id === payload.accountId,
    );
    if (index > -1) {
      const currentElement = state.AccountingInquiryMap[index];
      if (!currentElement.lineItemsMap) {
        currentElement.lineItemsMap = [{ ...payload.lineItem }];
      } else {
        currentElement.lineItemsMap = [
          payload.lineItem,
          ...currentElement.lineItemsMap,
        ];
      }
      currentElement.lineItemsActiveTab = 1;
      state.AccountingInquiryMap[index] = currentElement;

      router.push({
        path: `/accounting/accounts-payable/${payload.accountId}/line-item/${payload.lineItem.li}`,
      });
    }
  },
  UPDATE_ACTIVE_TAB(state, tabIndex: number) {
    state.activeTab = tabIndex;
  },
  REMOVE_OPENED_ACCOUNTING_INQUIRY_TAB(state, elem: any) {
    const id = AccountingObject.hydrate(elem)?.getRecordId();

    const index = state.AccountingInquiryMap.findIndex(
      (item: any) => AccountingObject.hydrate(item)?.getRecordId() === id,
    );
    let idToPush = "";
    let typeToPush = "";

    if (index < 0) return;

    if (
      index === 0 &&
      state.activeTab === 1 &&
      state.AccountingInquiryMap.length === 1
    ) {
      state.activeTab = 0;
      state.AccountingInquiryMap[0].lineItemsActiveTab = 0;
      state.AccountingInquiryMap[0].activeInnerTab = 0;
      router.push({ path: `/accounting/${elem.accountType}` });
    } else if (
      state.activeTab < state.AccountingInquiryMap.length &&
      state.AccountingInquiryMap.length > 1
    ) {
      idToPush =
        AccountingObject.hydrate(
          state.AccountingInquiryMap[state.activeTab],
        )?.getRecordId() || "";
      typeToPush = state.AccountingInquiryMap[state.activeTab].accountType;
    } else if (
      index + 1 === state.activeTab &&
      state.AccountingInquiryMap.length === index + 1 &&
      state.AccountingInquiryMap.length > 1
    ) {
      state.activeTab = state.activeTab - 1;
      const activeTab = AccountingObject.hydrate(
        state.AccountingInquiryMap[index - 1],
      );
      idToPush = activeTab?.getRecordId() || "";
      typeToPush = activeTab?.accountType || "";
    } else if (index + 1 < state.activeTab) {
      state.activeTab = state.activeTab - 1;
    } else if (index + 1 > state.activeTab && state.activeTab > 0) {
      const activeTab = AccountingObject.hydrate(
        state.AccountingInquiryMap[state.activeTab - 1],
      );
      idToPush = activeTab?.getRecordId() || "";
      typeToPush = activeTab?.accountType || "";
    }

    state.AccountingInquiryMap = state.AccountingInquiryMap.filter(
      (item: any) => AccountingObject.hydrate(item)?.getRecordId() !== id,
    );

    const data = AccountingObject.hydrate(
      state.AccountingInquiryMap[state.activeTab - 1],
    );

    if (idToPush && typeToPush && data && state.activeTab > 0) {
      router.push({
        path: `/accounting/${data.accountType}/${
          data.record.getId()
            ? (data.record.getId() as string)
            : ((data as any).record.id as string)
        }`,
      });
    } else {
      router.push({ path: `/accounting/${elem.accountType}` });
    }
  },
  UPDATE_LINE_ITEM_TAB(state, payload) {
    const index = state.AccountingInquiryMap.findIndex(
      (item: any) => item.record.ap_id === payload.accountId,
    );
    if (index > -1) {
      const currentElement = state.AccountingInquiryMap[index];
      currentElement.lineItemsActiveTab = payload.tabIndex;
      state.AccountingInquiryMap[index] = currentElement;
    }
  },
  UPDATE_ACTIVE_INNER_TAB(state, payload) {
    const index = state.AccountingInquiryMap.findIndex(
      (account: any) =>
        account.record.ap_id === payload.accountId ||
        account.record.transaction_id === payload.accountId,
    );
    if (index > -1) {
      state.AccountingInquiryMap[index].activeInnerTab = payload.tabIndex;
    }
  },
  REMOVE_LINE_ITEM_MAP(state, payload: { lineItem: any; accountId: number }) {
    const index = state.AccountingInquiryMap.findIndex(
      (item: any) => item.record.ap_id === payload.accountId,
    );
    if (index > -1) {
      state.AccountingInquiryMap[index].lineItemsActiveTab = 0;
      state.AccountingInquiryMap[index].activeInnerTab = 0;
      state.AccountingInquiryMap[index].lineItemsMap =
        state.AccountingInquiryMap[index].lineItemsMap.filter(
          (item: any) => item.li !== payload.lineItem.li,
        );
    }
  },
  PUSH_GL_LINE_ITEM(state, payload: any) {
    const { item, accountId } = payload;
    const index = state.AccountingInquiryMap.findIndex(
      (item: any) => item.record.transaction_id === accountId,
    );
    if (
      index > -1 &&
      state.AccountingInquiryMap[index].record instanceof GeneralLedgerEntry
    ) {
      (
        state.AccountingInquiryMap[index].record as GeneralLedgerEntry
      ).lineItems.push(item);
    }
  },
  SET_FISCAL_YEARS(state, payload: any) {
    state.FiscalYears = payload;
  },
  SET_GL_ACCOUNTS(state, payload: any) {
    state.Accounts = payload;
  },
  UPDATE_ORDER_MAP(state, payload: any) {
    const index = state.AccountingInquiryMap.findIndex(
      (item) =>
        item.accountType === "orders" &&
        item.record.getId() === payload.record.so_id,
    );
    if (index > -1) {
      state.AccountingInquiryMap[index].record = payload.record;
      state.AccountingInquiryMap[index].old_record = payload.old_record;
    }
  },
};

export const actions: ActionTree<AccountingInquiryState, RootState> = {
  pushAccountingInquiryMap({ commit }, payload) {
    commit("PUSH_ACCOUNTING_INQUIRY_MAP", payload);
  },
  pushAccountingLineItemMap({ commit }, payload) {
    const index = state.AccountingInquiryMap.findIndex(
      (item: any) => item.record.ap_id === payload.accountId,
    );
    if (index > -1) {
      const isOpened =
        state.AccountingInquiryMap[index].lineItemsMap.filter(
          (item: any) => item.li === payload.lineItem.li,
        ) ?? [];

      if (isOpened.length === 0) {
        commit("PUSH_LINE_ITEM_MAP", { ...payload });
        commit("UPDATE_LINE_ITEM_TAB", {
          accountId: payload.accountId,
          tabIndex: 1,
        });
      } else {
        const auxIndex = state.AccountingInquiryMap[
          index
        ].lineItemsMap.findIndex(
          (item: any) => item.li === payload.lineItem.li,
        );
        commit("UPDATE_LINE_ITEM_TAB", {
          accountId: payload.accountId,
          tabIndex: auxIndex + 1,
        });
        router.push({
          path: `/accounting/accounts-payable/${payload.accountId}/line-item/${payload.lineItem.li}`,
        });
      }
    }
  },
  updateActiveTab({ commit }, payload) {
    commit("UPDATE_ACTIVE_TAB", payload);
  },
  removeOpenedAccountingInquiryTab({ commit }, payload) {
    commit("REMOVE_OPENED_ACCOUNTING_INQUIRY_TAB", payload);
  },
  addOpenedAccountingTab({ commit, state }, payload: AccountingObject) {
    const id = payload.record.getId();

    const index = state.AccountingInquiryMap.findIndex(
      (item: any) => AccountingObject.hydrate(item)?.getRecordId() === id,
    );
    if (index < 0) {
      commit("PUSH_ACCOUNTING_INQUIRY_MAP", { ...payload });
      commit("UPDATE_ACTIVE_TAB", 1);
    } else {
      commit("UPDATE_ACTIVE_TAB", index + 1);
    }
  },
  changeActiveTab({ commit }, tabIndex: number) {
    commit("UPDATE_ACTIVE_TAB", tabIndex);
  },
  changeLineItemTab({ commit }, payload) {
    commit("UPDATE_LINE_ITEM_TAB", payload);
  },
  changeActiveInnerTab({ commit }, payload) {
    commit("UPDATE_ACTIVE_INNER_TAB", payload);
  },
  removeOpenedLineItem({ commit }, payload) {
    commit("REMOVE_LINE_ITEM_MAP", { ...payload });
  },
  addGLLineItem({ commit }, payload) {
    commit("PUSH_GL_LINE_ITEM", { ...payload });
  },
  fetchGlAccounts({ commit, dispatch, rootGetters }, fieldnames) {
    if (state.Accounts.length > 0) return Promise.resolve(state.Accounts);
    return new Promise((resolve, reject) => {
      glService
        .getAccounts(fieldnames, rootGetters["session/getClient"])
        .then((response: any) => {
          commit("SET_GL_ACCOUNTS", response.glchart_items);
          resolve(response.glchart_items);
        })
        .catch((error) => {
          const message = error.response.data.response.error;
          dispatch(
            "notification/add",
            {
              message: `${message ? message : error.message}`,
              type: "error",
            },
            { root: true },
          );
          reject({ success: false });
        });
    });
  },
  fetchGlTransactions({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      glService
        .getGeneralLedgerTransactions(payload)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          const message = error.response.data.response.error;
          dispatch(
            "notification/add",
            {
              message: `${message ? message : error.message}`,
              type: "error",
            },
            { root: true },
          );
          reject({ success: false });
        });
    });
  },
  postGlTransaction({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      glService
        .postGeneralLedgerEntry(payload)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          const message = error.response.data.response.error;
          dispatch(
            "notification/add",
            {
              message: `${message ? message : error.message}`,
              type: "error",
            },
            { root: true },
          );
          reject({ success: false });
        });
    });
  },
  async fetchFiscalYears({ commit, state }, refresh = false) {
    return new Promise((resolve, reject) => {
      if (!refresh && state.FiscalYears.length > 0) {
        resolve({ success: true, fy_items: state.FiscalYears });
      }

      fiscalYearService
        .getFiscalYears()
        .then((response: any) => {
          commit("SET_FISCAL_YEARS", response.fy_items);
          resolve({ success: true, fy_items: response.fy_items });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        });
    });
  },
  updateOrder({ commit }, payload) {
    commit("UPDATE_ORDER_MAP", payload);
  },
};

export const accountingInquiry: Module<AccountingInquiryState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
