import Part from "../part";
import PO from "../po";
import Receipts from "../receipts";
import InventoryState from "./inventory";
import Commit from "../commit";
import Alloc from "../alloc";
import Transaction from "../transaction";
import Order from "../order";
import Lot from "../lot";
import Usage from "../usage";
import { cloneDeep } from "lodash";

export default interface InventoryModuleState {
  InventoryMap: Array<InventoryObject<InventoryType>>;
  activeTab: number;
}

export const FileType = {
  PARTS: "parts",
  RECEIPTS: "receipts",
};

export class TemplateObject<T> {
  record: T;
  old_record: T;
  id: string;
  fileType: string;

  constructor(obj: T) {
    this.id = (crypto as any).randomUUID() as string;
    this.record = obj;
    this.old_record = cloneDeep(obj);
    this.fileType = "";
  }
}

export interface ReceiptsMetaData {
  po_number: string;
}

export interface EnsurePartResult {
  success: boolean;
  error?: string | Array<string>;
  part?: PartsObject;
  id?: string;
}

export type FileType = "parts" | "receipts";

export type InventoryType = Receipts | Part;

export abstract class InventoryObject<
  T extends InventoryType,
> extends TemplateObject<T> {
  abstract get getTab(): string;
  abstract get getRoute(): string;
  isType(type: FileType): boolean {
    return this.fileType === type;
  }
}

export class ReceiptsObject extends InventoryObject<Receipts> {
  metaData: ReceiptsMetaData;
  po?: PO;
  get getTab(): string {
    const id = this.old_record?.receipts_id;
    if (id) {
      return "Receipt #" + id;
    } else {
      return "Receipt New";
    }
  }

  get getRoute(): string {
    const id = this.old_record?.receipts_id;

    if (id) {
      return `/inventory/receipts/${id}`;
    } else {
      return `/inventory/receipts/new/${this.id}`;
    }
  }

  constructor(receipt: Receipts, metaData: ReceiptsMetaData, po?: PO) {
    super(receipt);
    this.metaData = metaData;
    this.po = po;
    this.fileType = FileType.RECEIPTS;
  }
}

export class PartsObject extends InventoryObject<Part> {
  inv?: InventoryState;
  commit?: Commit;
  alloc?: Alloc;
  transactions?: Array<Transaction>;
  order?: Order;
  lots?: Array<Lot>;
  shorts?: any;
  usage?: Usage;

  constructor(part: Part) {
    super(part);
    this.fileType = FileType.PARTS;
  }

  get getTab(): string {
    const id = this.old_record?.part_no;
    if (id) {
      return "Part #" + id;
    } else {
      return "Part New";
    }
  }

  get hasInventoryRecord(): boolean {
    if (this.record.inv_id) {
      return true;
    }

    return false;
  }

  get getRoute(): string {
    const id = this.old_record?.part_no;

    if (id) {
      return `/inventory/parts/${id}`;
    } else {
      return `/inventory/parts/new/${this.id}`;
    }
  }
}

export interface ReceiptsMetaData {
  po_number: string;
}

export function isPartsObject(
  object: InventoryObject<Receipts | Part>,
): boolean {
  return object.isType("parts");
}

export function isReceiptsObject(
  object: InventoryObject<Receipts | Part>,
): boolean {
  return object.isType("receipts");
}
