import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";

import ThemeState from "@/types/state/theme";

const namespaced = true;

const APP_LOGO =
  "https://prodroverstorage.blob.core.windows.net/rover/Rover-Logo-2-High-1.png";

export const state: ThemeState = {
  backgroundColor: "",
  secondaryColor: "",
  primaryColor: "",
  sidebarTextColor: "",
  logo: "",
  title: "",
  sidebarBGColor: "",
  tabLogo: "",
  customLogo: "",
};
export const mutations: MutationTree<ThemeState> = {
  SET_PRIMARY(state, color) {
    state.primaryColor = color;
  },
  SET_SECONDARY(state, color) {
    state.secondaryColor = color;
  },
  SET_BACKGROUND(state, color) {
    state.backgroundColor = color;
  },
  SET_SIDEBARTEXT(state, color) {
    state.sidebarTextColor = color;
  },
  SET_LOGO(state, url) {
    state.logo = url;
  },
  SET_TITLE(state, title) {
    state.title = title;
  },
  SET_SIDEBAR_BG_COLOR(state, color) {
    state.sidebarBGColor = color;
  },
  SET_TAB_LOGO(state, logo) {
    state.tabLogo = logo;
  },
  SET_CUSTOM_LOGO(state, logo) {
    state.customLogo = logo;
  },
};

export const getters: GetterTree<ThemeState, RootState> = {
  getLogo(state) {
    return state.logo;
  },
  getBackgroundColor(state) {
    return state.backgroundColor;
  },
  getSidebarTextColor(state) {
    return state.sidebarTextColor;
  },
  getTitle(state) {
    return state.title;
  },
  getCustomLogo(state) {
    return state.customLogo || APP_LOGO;
  },
};

export const actions: ActionTree<ThemeState, RootState> = {
  setTheme({ commit, state, dispatch }, payload: ThemeState) {
    commit("SET_PRIMARY", payload.primaryColor);
    commit("SET_SECONDARY", payload.secondaryColor);
    commit("SET_BACKGROUND", payload.backgroundColor);
    commit("SET_SIDEBARTEXT", payload.sidebarTextColor);
    commit("SET_LOGO", payload.logo);
    commit("SET_TITLE", payload.title);
    commit("SET_SIDEBAR_BG_COLOR", payload.sidebarBGColor);
    commit("SET_TAB_LOGO", payload.tabLogo);
    commit("SET_CUSTOM_LOGO", payload.customLogo);
    dispatch("updateTheme");
  },
  updateTheme({ state }) {
    const root = document.documentElement;
    const icon = document.getElementById("icon");
    icon?.setAttribute("href", state.tabLogo);
    root.style.setProperty("--sidebar-bg-color", state.sidebarBGColor);
    root.style.setProperty("--sidebar-link-color", state.sidebarTextColor);
    root.style.setProperty("--default-button-color", state.secondaryColor);
    root.style.setProperty("--default-bg-color", state.backgroundColor);
    root.style.setProperty("--primary-color", state.primaryColor);
  },
};

export const theme: Module<ThemeState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
};
