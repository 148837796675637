
import { defineComponent } from "@vue/runtime-core";
import { mapState, mapGetters, mapActions } from "vuex";
import { mapActions as mapActionsPinia } from "pinia";
import EventBus, { AppEvents } from "@/utility/EventBus";

import Button from "primevue/button";
import SideMenu from "@/components/SideMenu.vue";
import TieredMenu from "primevue/tieredmenu";
import NotificationCard from "@/components/Notification-Card.vue";
import CacheOfflineDataButton from "@/components/Pos/CacheOfflineDataButton.vue";
import ShortcutsDialog from "@/components/ShortcutsDialog.vue";
import Footer from "@/components/Footer.vue";
import InformerAIForm from "./components/InformerAIForm.vue";

import BaseCustomer from "@/types/basecustomer";
import store from "@/store";
import { piniaHandleLogout } from "./utility/pinia";
import { useContactControlStore } from "./store/data/controls/contact";

export default defineComponent({
  name: "App",
  components: {
    SideMenu,
    Footer,
    TieredMenu,
    Button,
    NotificationCard,
    CacheOfflineDataButton,
    ShortcutsDialog,
    InformerAIForm,
  },
  beforeCreate() {
    store.dispatch("theme/updateTheme");
    document.title = store.getters["theme/getTitle"] + " " + "Customer Portal";
  },
  created() {
    if (screen.width < 480) {
      store.dispatch("sidemenu/setCollapsed");
    }
  },
  mounted() {
    const getResetTimer = this.resetTimer;
    this.events.forEach((event) => {
      window.addEventListener(event, getResetTimer);
    }, this);
    this.setTimers();
    window.addEventListener("resize", this.handleWindowResize);
    window.addEventListener("keydown", this.handleKeydownEvent);
    (document.getElementById("app") as any)?.addEventListener(
      "scroll",
      this.handleScroll,
    );

    EventBus.on(AppEvents.ShowKeyboardShortcuts, () => {
      this.showShortcutsDialog = true;
    });
  },
  unmounted() {
    const getResetTimer = this.resetTimer;
    this.events.forEach((event) => {
      window.removeEventListener(event, getResetTimer);
    }, this);
    this.resetTimer();
    this.$posthog.reset();
  },
  beforeUnmount() {
    EventBus.off(AppEvents.PosClearDraftOrders);
  },
  computed: {
    ...mapState(["session", "sidemenu", "customer", "theme", "title", "pos"]),
    ...mapGetters({
      getModules: "env/getModules",
      getClient: "session/getClient",
      getDefaultCodeItem: "coControl/getDefaultCodeItem",
      getCustomLogo: "theme/getCustomLogo",
    }),
    allCustomers(): Array<BaseCustomer> {
      return store.getters["customer/getAllCusts"];
    },
    sidemenuWidth(): number {
      if (this.windowWidth < 1200 && this.sidemenu.collapsed) {
        return 0;
      }
      return this.sidemenu.sidebarWidth;
    },
    showAccountIcon(): boolean {
      return !this.$route.fullPath.includes("/scan/");
    },
    isPointOfSale(): boolean {
      return this.$route.fullPath.includes("/pos/");
    },
    printingView(): boolean {
      return this.$route.path.includes("printable-view");
    },
  },
  methods: {
    ...mapActions({
      changeTitle: "title/changeTitle",
      setAutoAddPart: "pos/setAutoAddParts",
      setAutoLoadDefaultCustomers: "pos/setAutoLoadDefaultCustomers",
      fetchModules: "env/fetchModules",
      fetchInformerAI: "ai/fetchInformerAI",
      fetchControls: "control/fetchControl",
      getMrkControl: "mrkControl/getMrkControl",
    }),
    ...mapActionsPinia(useContactControlStore, ["fetchContactControl"]),
    toggleSideMenu() {
      store.dispatch("sidemenu/toggle");
    },
    toggleSideMenuOnMobile() {
      if (this.windowWidth < 1200) {
        store.dispatch("sidemenu/toggle");
      }
    },
    logout() {
      this.$posthog.reset();
      piniaHandleLogout();
      store.dispatch("session/logout");
    },
    setTimers() {
      this.LogoutTimer = setTimeout(this.logout, 8 * 60 * 60 * 1000);
    },
    resetTimer() {
      clearTimeout(this.LogoutTimer);
      this.setTimers();
    },
    toggleProfileMenu(event: any) {
      (this.$refs.menu as any).toggle(event);
    },
    settings() {
      this.changeTitle("Settings");
      this.$router.push("/Settings");
    },
    navigateToPage() {
      // TODO: Need to re-map title to route
      const title = this.title.title.toLowerCase();
      switch (title) {
        case "scan":
          store.commit("title/NEXT_SUB_TITLE", "");
          this.$router.push("/scan");
          break;
        case "tickets & time":
          this.$router.push("/tickets");
          break;
        case "dashboard":
          this.$router.push("/dashboard");
          break;
        default:
          this.$router.push("/" + title);
      }
    },
    handleScroll() {
      var currentScrollPos = (document.getElementById("app") as any)?.scrollTop;
      if (this.prevScrollPos > currentScrollPos && this.windowWidth > 576) {
        (document.getElementById("topbar") as any).style.top = "0";
        (document.getElementById("topbar") as any).style.position = "sticky";
      } else {
        // It will hide the topbar only if the sidebar is collapsed and tablet width
        if (
          this.sidemenu.collapsed &&
          this.windowWidth < 1200 &&
          this.windowWidth > 576
        ) {
          (document.getElementById("topbar") as any).style.top = "-80px";
          (document.getElementById("topbar") as any).style.position =
            "relative";
        }
      }

      this.prevScrollPos = currentScrollPos;
    },
    handleWindowResize() {
      this.windowWidth = window.innerWidth;
    },
    handleKeydownEvent(event: KeyboardEvent) {
      if (event.key === "?" && event.ctrlKey && event.shiftKey) {
        this.showShortcutsDialog = true;
      }
    },
  },
  data() {
    return {
      prevScrollPos: 0,
      windowWidth: window.innerWidth,
      selectedCustomer: null,
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      LogoutTimer: 0,
      customers: [],
      menuitems: [
        {
          label: "Log Out",
          icon: "pi pi-sign-out",
          command: this.logout,
        },
        {
          label: "Settings",
          icon: "pi pi-cog",
          command: this.settings,
        },
      ],
      showMenu: false,
      showShortcutsDialog: false,
    };
  },
  watch: {
    getClient: {
      async handler() {
        if (this.getClient) {
          const promises = [
            store.dispatch("fdict/fetchFdict", "CUST"),
            store.dispatch("fdict/fetchFdict", "SO"),
            store.dispatch("fdict/fetchFdict", "PARTS"),
            store.dispatch("fdict/fetchFdict", "PO"),
            store.dispatch("users/fetchUsers", {}),
            store.dispatch("coControl/fetchCoControl"),
            store.dispatch("sidemenu/fetchWebUserSettingControl"),
          ];
          try {
            await Promise.allSettled(promises);
            this.getMrkControl({
              client: this.getClient,
              selectedCode: this.getDefaultCodeItem,
            });
            this.fetchModules({
              client: this.getClient,
              selectedCode: this.getDefaultCodeItem,
            });
            this.fetchInformerAI({
              RoverUser: this.session.user,
            });
            this.fetchContactControl();
          } catch (err) {
            // noop
          }
        }
      },
      immediate: true,
    },
  },
  provide() {
    return {
      isProduction: window.location.origin.includes("app.rovererp.com"),
    };
  },
});
