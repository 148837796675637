import { defineStore } from "pinia";

import { useLocalStorage } from "@vueuse/core";

import { ref, computed } from "@vue/runtime-core";
import ControlService from "@/services/ControlService";
import { PiniaLocalStorageKeys } from "@/utility/PiniaLocalStorageKeys";

const controlService = new ControlService();

export interface ContactControl {
  control_id: string;
  contact_lookup: string;
  contact_lookup_desc: string;
  contact_id_items: ContactIdItem[];
}

export interface ContactIdItem {
  contact_id: string;
  description: string;
  file_name_items: FileNameItem[];
}

export interface FileNameItem {
  file_name: string;
}

export const useContactControlStore = defineStore("contactControl", () => {
  const data = useLocalStorage(
    PiniaLocalStorageKeys.CONTACT_CONTROL_DATA,
    ref<ContactControl>({} as ContactControl),
  );

  const getContactLookup = computed(() => data.value.contact_lookup);

  const getContactIdItems = computed(() => data.value.contact_id_items);

  async function fetchContactControl() {
    if (data.value.control_id) {
      return data.value;
    }

    const contactControl = (await controlService.getControl(
      "CONTACT",
      "CONTACT.CONTROL",
      "CONTROL",
      "",
    )) as ContactControl[];

    if (contactControl && contactControl.length > 0) {
      data.value.control_id = contactControl[0].control_id || "";
      data.value.contact_lookup = contactControl[0].contact_lookup || "";
      data.value.contact_lookup_desc =
        contactControl[0].contact_lookup_desc || "";
      data.value.contact_id_items = contactControl[0].contact_id_items || [];
    }

    return data.value;
  }

  return {
    getContactLookup,
    getContactIdItems,
    fetchContactControl,
  };
});
