
import { defineComponent, ref } from "vue";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Card from "primevue/card";

export default defineComponent({
  components: {
    Dialog,
    DataTable,
    Column,
    Card,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  setup() {
    const showDialog = ref(false);
    const posPartsShortCuts = ref([
      { action: "Add Part", shortcut: ["Enter"] },
      { action: "Move to next part", shortcut: ["Shift", "pi pi-arrow-down"] },
      {
        action: "Move to previous part",
        shortcut: ["Shift", "pi pi-arrow-up"],
      },
      { action: "Focus parts search", shortcut: ["Shift", "F"] },
      { action: "Open Cart", shortcut: ["Ctrl", "C"] },
      { action: "Add line item in cart", shortcut: ["Ctrl", "A"] },
    ]);
    return {
      showDialog,
      posPartsShortCuts,
    };
  },
  computed: {
    isPosPartsSection(): boolean {
      return this.$route.path.toLowerCase().includes("/pos/parts");
    },
    isSettingsSection(): boolean {
      return this.$route.path.toLowerCase().includes("/settings");
    },
    allowShortCuts(): boolean {
      return this.isPosPartsSection || this.isSettingsSection;
    },
  },
  watch: {
    visible(newVal) {
      if (this.allowShortCuts) {
        this.showDialog = newVal;
      }
    },
  },
});
