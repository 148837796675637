import { AxiosInstance } from "axios";
import axios from "axios";
import ClientAPI, { DEFAULT_CLIENT_SUCCESS_HANDLER } from "./ClientAPI";
import { NotificationType } from "@/types/notification";

export default class TicketService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getTickets(
    rangeStart: any,
    rangeEnd: any,
    custs: any,
    ids: any,
    status: any,
    assignee: any,
    assignees: any,
    sortBy: any,
    sortOrder: any,
    correls: any,
    types: any,
  ) {
    for (let i = 0; i < assignees.length; i++) {
      if (assignees[i] == null || assignees[i] == "") {
        assignees[i] = "UA";
      }
    }

    const params = {
      rangeStart,
      rangeEnd,
      custs,
      ids,
      status,
      assignee,
      assignees,
      sortBy,
      sortOrder,
      correls,
      types,
    };
    return ClientAPI.Get(`tickets`, params);
  }

  async getKpiInfo(
    cust: string,
    month: string,
    year: string,
    successHandler?: any,
  ) {
    const params = {
      cust,
      month,
      year,
    };
    const error = () =>
      ClientAPI.displayNotification(NotificationType.ERROR, "");
    return ClientAPI.Get(
      `tickets/kpi`,
      params,
      successHandler || DEFAULT_CLIENT_SUCCESS_HANDLER,
      error,
    );
  }

  async getTicketsSearch(
    rangeStart: any,
    rangeEnd: any,
    custs: any,
    ids: any,
    status: any,
    assignee: any,
    assignees: any,
    sortBy: any,
    sortOrder: any,
    correls: any,
    types: any,
    priorities: any,
    statuses: any,
  ) {
    for (let i = 0; i < assignees.length; i++) {
      if (assignees[i] == null || assignees[i] == "") {
        assignees[i] = "UA";
      }
    }

    const params: any = {
      custs,
      rangeStart: rangeStart.toString(),
      rangeEnd: rangeEnd.toString(),
      ids,
      status,
      assignee,
      assignees,
      sortBy,
      sortOrder,
      correls,
      types,
      priorities,
      statuses,
    };
    if (ids !== "") {
      params["rangeStart"] = "1";
      params["rangeEnd"] = ids.split(" ").length.toString();
    }

    return ClientAPI.Post(`tickets/search`, params);
  }

  async newTicket(payload: any) {
    return ClientAPI.Post(`tickets`, payload);
  }

  async updateTicket(payload: any) {
    const { ticket_id, newTicket, oldTicket } = payload;
    return ClientAPI.Put(`tickets/${ticket_id}`, { newTicket, oldTicket });
  }
}
